<template>
  <div class="">
    <div class="lable">联系方式<i>*</i></div>
    <PaInput
      class="myInputType"
      v-model="from.name"
      placeholder="请输入名字"
      is_required_text="请输入名字"
      :submitType="submitType"
      :is_required="true"
    />
    <Pacode
      class="myInputType"
      v-model="from.email"
      placeholder="请输入邮箱"
      is_required_text="请输入邮箱"
      @clickCode="clickCode"
      :submitType="submitType"
      successContent="邮箱将用于注册平台账号以及发送审核结果，请填写您的真实邮箱。"
    />
    <PaInput
      class="myInputType"
      type="Code"
      v-model="from.code"
      placeholder="请输入验证码"
      error_content="验证码无效"
      is_required_text="请输入验证码"
      :submitType="submitType"
      :is_required="true"
    />
    <PaInput
      class="myInputType"
      v-model="from.phoneNo"
      type="phoneNo"
      placeholder="请输入手机号"
      is_required_text="请输入手机号"
      :submitType="submitType"
      :is_required="true"
    />
    <div class="checkbox">
      <input type="checkbox" v-model="isChecked" />
      <span @click="clickCheckbox">我已阅读并同意</span>
      <i @click="openPages('SiteConstructionServiceAgreement')">《建站服务协议》</i>
      <i @click="openPages('TermsOfService')">《隐私政策》</i>
      <i @click="openPages('PrivacyPolicy')">《服务条款》</i>
    </div>
    <div class="nextButten" @click="next">下一步</div>
  </div>
</template>
<script>
import store from "@/store/index";
import { mapState, mapMutations } from "vuex";
import Pacode from "@/components/basic/Pacode.vue";
import PaInput from "@/components/basic/PaInput.vue";
import { register } from "@/assets/js/api";
import { handleResponseErrors } from "@/assets/js/utils";
export default {
  store,
  created() {
    let ERFrom = JSON.parse(sessionStorage.getItem("fpp-ERFrom")) || {};
    Object.keys(this.from).forEach(key => {
      let objCople = Array.isArray(this.from[key].value) ? [] : "";
      this.from[key].value = ERFrom[key] || objCople;
    });
    if (this.$route.query.email)
      this.from.email.value = this.$route.query.email;
  },
  data() {
    return {
      from: {
        name: { value: "", isError: false },
        email: { value: "", isError: false },
        code: { value: "", isError: false },
        phoneNo: { value: "", isError: false }
      },
      isChecked: false,
      submitType: false,
      session_id: ""
    };
  },
  components: { PaInput, Pacode },
  computed: { ...mapState(["ERFrom"]) },
  methods: {
    ...mapMutations(["upData_ERFrom"]),
    clickCheckbox() {
      this.isChecked = !this.isChecked;
    },
    openPages(pages) {
      let url = window.location.origin + "/register/agreement/" + pages;
      window.open(url, "_blank"); 
    },
    async clickCode() {
      let data = { email: this.from.email.value, verify_type: "register" };
      try {
        let ref = await register.getCode(data);
        if (ref.code != 0) {
          handleResponseErrors(ref.code, ref.msg);
          return;
        }else{
          handleResponseErrors(ref.code, ref.msg);
        }
        this.session_id = ref.session_id;
      } catch (error) {
        console.log(error);
      }
    },
    async sendForm() {
      let business = this.$route.query.business || localStorage.getItem("business");
      let channel = this.$route.query.channel || localStorage.getItem("channel");
      let data = {
        login_name: this.from.name.value,
        session_id: this.session_id,
        email: this.from.email.value,
        verify_code: this.from.code.value,
        seller_phone: this.from.phoneNo.value,
        seller_type: this.$route.path.match(/enterprise/) ? 2 : 1, //1个人 2企业
        refer_user_id: business
          ? window.atob(decodeURIComponent(business))
          : business,
        channel:channel || 1
      };
      let ref = await register.sendStep1(data);
      if (ref.code != 0) {
        handleResponseErrors(ref.code, ref.msg);
        throw new Error("auth_failed");
        return;
      }
      localStorage.setItem("secret_key", ref.secret_key);
      localStorage.setItem("seller_id", ref.seller_id);
    },
    next() {
      window.gtag("event", "Click-button-e1", {
        event_category: "register",
        event_label: "enterprise"
      });
      this.submitType = true;
      if (!this.isChecked) {
        this.$message({
          message: "请先同意服务条款",
          type: "warning"
        });
        return;
      }
      this.$nextTick(async () => {
        let query = {};
        if (this.$route.query) {
          query = {
            email: this.$route.query.email,
            business: this.$route.query.business,
            channel: this.$route.query.channel
          };
        }
        if (Object.values(this.from).some(item => item.isError)) {
          return;
        }
        try {
          if (
            Object.values(this.from).every(item => {
              return item.isError != true;
            })
          ) {
            let form = this.ERFrom;
            Object.keys(this.from).forEach(key => {
              if (key != "code") {
                form[key] = this.from[key].value;
              }
            });
            this.upData_ERFrom(form);
            await this.sendForm();
            window.gtag("event", "Click-button-e1-success", {
              event_category: "register",
              event_label: "enterprise"
            });
            this.$router.push({
              path: "/register/enterprise/Step2",
              query
            });
          }
        } catch (error) {
          if (error.toString().match(/auth_failed/)) {
            return;
          }
          console.log(error);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.lable {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: $black;
  line-height: 28px;
  i {
    color: red;
    font-style: normal;
  }
}
.myInputType {
  width: 570px;
  margin-top: 20px;
}
.checkbox {
  margin-top: 30px;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: $black;
  line-height: 25px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
  i {
    color: $green;
    font-style: normal;
  }
  input[type="checkbox"] {
    vertical-align: text-top;
    border: 1px solid #7e8993;
    border-radius: 4px;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    outline: 0;
    padding: 0 !important;
    text-align: center;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: 0.05s border-color ease-in-out;
    position: relative;
  }
  input[type="checkbox"]:checked::before {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAA7UlEQVQ4T+3UP27CMBQG8O9Tew7OwhUQdoZKXWBjqPCA2CqxVa3qTGxMLFR5QeoRuAQch4cKigJJsAExoXqznv2T3h+buPPinT08MNjN/DuJCcBxbt1nUbqbUjbyPQb4USBKviyNW/ztrwaNpENA0+NmkvwV4zqNoJGvdm5Hq6buG/EDANNKbE19TiR529RAI2kGqAUouXXJ8UWb+b4SsxB2AhrxHoArL5SozdJXpc5jWATch4W6XSr5cwkWSDn4fk5qVj1Z63JZx0Y0iJ0dmzNoFAvOYQW9CIsO9gFFi/rUK+Ys9jtd/VL+wVoFdgBRahXxksrVAAAAAElFTkSuQmCC");
    height: 20px;
    width: 20px;
  }
}
.nextButten {
  width: 160px;
  height: 52px;
  background: $green;
  border-radius: 8px;
  margin: 40px auto 72px;
  font-size: 20px;
  font-weight: 500;
  color: $fff;
  line-height: 52px;
  text-align: center;
  cursor: pointer;
}
</style>
